import React from 'react'
import { Layout, Pagination, Posts, SEO } from '../components'
import { graphql } from 'gatsby'

const ProjectListTemplate = props => {
  const {
    data: {
      allMdx: { nodes: projects },
    },
  } = props
  const { currentProjectPage, numProjectPages } = props.pageContext

  const isFirst = currentProjectPage === 1
  const isLast = currentProjectPage === numProjectPages

  const previousProjectPage =
    currentProjectPage - 1 === 1
      ? '/blog/projects/'
      : `/blog/projects/page/${(currentProjectPage - 1).toString()}/`
  const nextProjectPage = `/blog/projects/page/${(
    currentProjectPage + 1
  ).toString()}/`

  const currentPageTitle = '프로젝트'

  return (
    <Layout>
      <SEO title={`${currentPageTitle} - page ${currentProjectPage}`} />
      <Pagination
        division="project"
        isFirst={isFirst}
        isLast={isLast}
        prevPage={previousProjectPage}
        nextPage={nextProjectPage}
        numPages={numProjectPages}
      />
      {projects.length > 0 ? (
        <Posts posts={projects} title={currentPageTitle} />
      ) : null}
    </Layout>
  )
}

export const projectListQuery = graphql`
  query projectListQuery($skip: Int, $limit: Int) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { division: { eq: "project" }, published: { eq: true } }
      }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        excerpt(pruneLength: 100, truncate: true)
        frontmatter {
          title
          author
          division
          category
          subcategory
          tags
          date(formatString: "YYYY-MM-DD")
          slug
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 1200, placeholder: BLURRED)
            }
          }
        }
        id
        timeToRead
      }
    }
  }
`

export default ProjectListTemplate
